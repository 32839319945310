import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, CircularProgress, Box, Alert as MuiAlert } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../features/authContext';
import { db, functions } from '../firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, setDoc, increment } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import * as iban from 'iban';
import { useNavigate } from 'react-router-dom';

const WalletScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [ibanValue, setIbanValue] = useState('');
  const [balance, setBalance] = useState(null);
  const [totalSpent, setTotalSpent] = useState(0);
  const [currency] = useState('EUR');
  const [exchangeRates, setExchangeRates] = useState({});
  const [email, setEmail] = useState('');
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transfersEnabled, setTransfersEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (!currentUser) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const exchangeRatesRes = await axios.get('https://api.exchangerate-api.com/v4/latest/EUR');
        setExchangeRates(exchangeRatesRes.data.rates);

        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setEmail(userData.email);
          setStripeAccountId(userData.stripeAccountId);
          setTotalSpent(userData.totalSpent || 0);
          setTransfersEnabled(userData.stripeTransfersEnabled || false);

          // Calculer le solde en fonction des notifications payées
          const q = query(collection(db, 'notifications'), where('userId', '==', currentUser.uid), where('status', '==', 'paid'));
          const querySnapshot = await getDocs(q);
          let total = 0;

          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const amountField = parseFloat(data.totalPrice || data.amount || 0);
            const docCurrency = (data.currency || 'EUR').toUpperCase();

            if (docCurrency === 'EUR') {
              total += amountField;
            } else if (exchangeRates[docCurrency]) {
              total += amountField / exchangeRates[docCurrency];
            } else {
              total += amountField / 655;
            }
          });

          setBalance(total);
        } else {
          // Si l'utilisateur n'existe pas, initialiser les valeurs par défaut
          await setDoc(doc(db, 'users', currentUser.uid), { email: currentUser.email, balance: 0, totalSpent: 0, stripeAccountId: null });
          setBalance(0);
          setTotalSpent(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setAlert({ type: 'error', message: 'Erreur lors de la récupération des données.' });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const handleWithdrawalRequest = async () => {
    if (!currentUser) {
      setAlert({ type: 'error', message: 'Vous devez être connecté pour effectuer un retrait.' });
      return;
    }

    if (!amount || !ibanValue) {
      setAlert({ type: 'error', message: 'Veuillez saisir un montant et un IBAN.' });
      return;
    }

    if (!iban.isValid(ibanValue)) {
      setAlert({ type: 'error', message: 'Veuillez saisir un IBAN valide.' });
      return;
    }

    if (!stripeAccountId) {
      setAlert({
        type: 'warning',
        message: 'Vous devez créer un compte Stripe avant d’effectuer un retrait.',
        action: createStripeAccount,
      });
      return;
    }

    setIsSubmitting(true);
    const amountNumber = parseFloat(amount);
    const discountedAmount = (amountNumber * 0.98).toFixed(2); // Deduct 2%

    try {
      const createTransfer = httpsCallable(functions, 'createTransfer');
      await createTransfer({ amount: discountedAmount, currency, iban: ibanValue });

      // Mise à jour du solde et du total dépensé dans Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        balance: increment(-amountNumber),
        totalSpent: increment(amountNumber),
      });

      // Mise à jour locale du solde pour réflexion immédiate dans l'UI
      setBalance((prevBalance) => prevBalance - amountNumber);
      setTotalSpent((prevTotal) => prevTotal + amountNumber);

      setAlert({ type: 'success', message: 'Votre demande de retrait a été envoyée.' });
      navigate("/couturier-main");
    } catch (error) {
      console.error('Error creating withdrawal request:', error);
      setAlert({ type: 'error', message: 'Une erreur est survenue lors de la demande de retrait.' });
    }
    setIsSubmitting(false);
  };

  const createStripeAccount = async () => {
    try {
      const createStripeAccountFn = httpsCallable(functions, 'createStripeAccount');
      const result = await createStripeAccountFn({});
      const { accountLinkUrl } = result.data;
      setAlert({
        type: 'info',
        message: 'Veuillez compléter la configuration du compte dans le navigateur.',
        action: () => window.open(accountLinkUrl, '_blank'),
      });
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      setAlert({ type: 'error', message: 'Échec de la création du compte Stripe. Veuillez réessayer plus tard.' });
    }
  };

  const handleAmountChange = (event) => {
    const text = event.target.value;
    const filteredText = text.replace(/[^0-9.]/g, '');
    const dotIndex = filteredText.indexOf('.');
    if (dotIndex !== -1) {
      const beforeDot = filteredText.slice(0, dotIndex + 1);
      const afterDot = filteredText.slice(dotIndex + 1).replace(/\./g, '');
      setAmount(beforeDot + afterDot);
    } else {
      setAmount(filteredText);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!currentUser) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" align="center" gutterBottom>
          Veuillez vous connecter pour accéder à cette page.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Solde: {balance !== null ? balance.toFixed(2) : 'Chargement...'} €
      </Typography>
      <Typography variant="h4" align="center" gutterBottom>
        Total Dépensé: {totalSpent.toFixed(2)} €
      </Typography>
      {stripeAccountId && (
        <Typography variant="body1" color="success.main" align="center" gutterBottom>
          Votre compte Stripe est actif. Vous pouvez désormais effectuer des virements sur votre compte bancaire.
        </Typography>
      )}
      <Typography variant="h5" align="center" gutterBottom>
        Demander un retrait
      </Typography>
      {alert && (
        <MuiAlert severity={alert.type} action={alert.action && <Button color="inherit" size="small" onClick={alert.action}>Action</Button>}>
          {alert.message}
        </MuiAlert>
      )}
      <TextField
        fullWidth
        margin="normal"
        label="Montant"
        value={amount}
        onChange={handleAmountChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="IBAN"
        value={ibanValue}
        onChange={(e) => setIbanValue(e.target.value)}
      />
      <Box textAlign="center" mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleWithdrawalRequest}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Envoyer la demande'}
        </Button>
      </Box>
    </Container>
  );
};

export default WalletScreen;
