import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, Card, CardContent, CardMedia, IconButton, Grid } from '@mui/material';
import { collection, query, where, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const MyPostsScreen = () => {
  const [posts, setPosts] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllPosts = async () => {
      if (currentUser) {
        const collections = ['patterns', 'tissus', 'shoes', 'accessories', 'readytowear'];
        let allPosts = [];

        // Fetch posts from all collections
        for (let collectionName of collections) {
          const q = query(collection(db, collectionName), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(q);
          const postsData = querySnapshot.docs.map(doc => ({ id: doc.id, collectionName, ...doc.data() }));
          allPosts = [...allPosts, ...postsData];
        }

        // Sort posts by date or other criteria if necessary
        setPosts(allPosts);
      }
    };

    fetchAllPosts();
  }, [currentUser]);

  const handleDeletePost = async (postId, collectionName) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce post?")) {
      try {
        await deleteDoc(doc(db, collectionName, postId));
        setPosts(posts.filter(post => post.id !== postId));
        alert("Le post a été supprimé avec succès.");
      } catch (error) {
        console.error("Erreur lors de la suppression du post: ", error);
        alert("Une erreur est survenue lors de la suppression du post.");
      }
    }
  };

  const renderItem = (item) => (
    <Grid item xs={12} sm={6} md={4} key={item.id}>
      <Card>
      <CardMedia
  component="img"
  height="140"
  image={item.images[0]}
  alt={item.title}
  onClick={() => navigate(`/post/${item.id}/${item.collectionName}`, {  // Pass the collectionName as type
    state: {
      images: item.images,
      title: item.title,
      description: item.description,
      price: item.price,
      priceType: item.priceType,
      gender: item.gender,
      ageGroup: item.ageGroup,
      currency: item.currency,
      collectionName: item.collectionName 
    }
  })}
/>

        <CardContent>
          <Typography variant="h6" component="div">
            Prix: {item.price} {item.currency}
          </Typography>
          <IconButton aria-label="delete" onClick={() => handleDeletePost(item.id, item.collectionName)}>
            <DeleteIcon color="error" />
          </IconButton>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Mes Publications
      </Typography>
      <Grid container spacing={3}>
        {posts.map(renderItem)}
      </Grid>
    </Container>
  );
};

export default MyPostsScreen;
