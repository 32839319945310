import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Toolbar, Avatar, Button, Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { User, BookOpenCheck, Settings, Wallet } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { logout } from '../firebase/userAuth';
import logo from '../assets/logobis.png';  // Importez votre logo ici

const ProfileScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        const docRef = doc(db, 'profiles', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfile(docSnap.data());
        } else {
          console.log('No such document!');
        }
      }
    };
    fetchProfile();
  }, [currentUser]);

  const handleLogout = async () => {
    const res = await logout();
    if (res.success) {
      navigate('/auth');
    }
  };

  return (
    <Container maxWidth="sm">
      <AppBar position="static" sx={{ marginBottom: '1rem' }}>
        <Toolbar>
          <img src={logo} alt="Couturia Logo" style={{ height: '50px', marginRight: '1rem' }} />
          <Typography variant="h6">Profil</Typography>
        </Toolbar>
      </AppBar>

      <Box textAlign="center" mb={4}>
        {profile?.logo ? (
          <Avatar src={profile.logo} sx={{ width: 120, height: 120, margin: '0 auto' }} />
        ) : (
          <Avatar sx={{ width: 120, height: 120, margin: '0 auto', bgcolor: 'gray' }}>
            <User />
          </Avatar>
        )}
        <Typography variant="h5" mt={2}>{profile?.Name}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/user-profile/${currentUser.uid}')}
          sx={{ mt: 2 }}
        >
          Voir mon profil
        </Button>
      </Box>

      <List>
        <ListItem button onClick={() => navigate('/my-posts')}>
          <ListItemIcon>
            <BookOpenCheck />
          </ListItemIcon>
          <ListItemText primary="Mes publications" />
        </ListItem>
        <ListItem button onClick={() => navigate('/preferences')}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Préférences" />
        </ListItem>
        <ListItem button onClick={() => navigate('/wallet')}>
          <ListItemIcon>
            <Wallet />
          </ListItemIcon>
          <ListItemText primary="Porte Monnaie" />
        </ListItem>
      </List>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        sx={{ width: '100%', mt: 4 }}
      >
        Se Déconnecter
      </Button>
    </Container>
  );
};

export default ProfileScreen;
