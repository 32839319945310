import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Avatar,
  Box,
  Paper,
  Grid,
  CircularProgress,
  IconButton
} from '@mui/material';
import { Edit, Camera, BadgeCheck, CircleArrowLeft, Share2, User } from 'lucide-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const UserProfile = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { userId } = useParams();

  const [profile, setProfile] = useState(null);
  const [posts, setPosts] = useState([]);
  const [tissus, setTissus] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      if (userId) {
        try {
          const userDocRef = doc(db, "users", userId);
          const profileDocRef = doc(db, "profiles", userId);

          const userDocSnap = await getDoc(userDocRef);
          const profileDocSnap = await getDoc(profileDocRef);

          if (userDocSnap.exists() && profileDocSnap.exists()) {
            const userData = userDocSnap.data();
            const profileData = profileDocSnap.data();

            const profileImageUrl = profileData.logo;
            const profileLogoUrl = profileImageUrl.replace('http://', 'https://');
            const bannerImageUrl = profileData.banner;

            setProfile({
              ...profileData,
              Name: userData.name,
              banner: bannerImageUrl,
              logo: profileLogoUrl,
              isOfficial: userData.isOfficial,
              role: userData.role,
            });
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    const fetchPosts = async () => {
      if (userId) {
        try {
          const patternsQuery = query(collection(db, 'patterns'), where('userId', '==', userId));
          const patternsSnapshot = await getDocs(patternsQuery);
          const patternsData = patternsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'patterns' }));

          setPosts(patternsData);
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
      }
    };

    const fetchTissus = async () => {
      if (userId) {
        try {
          const tissusQuery = query(collection(db, 'tissus'), where('userId', '==', userId));
          const tissusSnapshot = await getDocs(tissusQuery);
          const tissusData = tissusSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'tissus' }));

          setTissus(tissusData);
        } catch (error) {
          console.error("Error fetching tissus:", error);
        }
      }
    };

    const fetchAccessories = async () => {
      if (userId) {
        try {
          const accessoriesQuery = query(collection(db, 'accessories'), where('userId', '==', userId));
          const accessoriesSnapshot = await getDocs(accessoriesQuery);
          const accessoriesData = accessoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'accessories' }));

          setAccessories(accessoriesData);
        } catch (error) {
          console.error("Error fetching accessories:", error);
        }
      }
    };

    fetchProfile();
    fetchPosts();
    fetchTissus();
    fetchAccessories();
  }, [userId]);

  const handleBannerPress = () => {
    if (currentUser?.uid === userId) {
      navigate('/modifier-banniere');
    }
  };

  const handleEditPress = () => {
    if (currentUser?.uid === userId) {
      navigate('/couturier-profile');
    }
  };

  const handleItemPress = (item) => {
    navigate('/post/${item.id}', {
      state: {
        images: item.images,
        title: item.title,
        description: item.description,
        price: item.price,
        priceType: item.priceType,
        currency: item.currency,
        type: item.type,
        id: item.id,
      }
    });
  };

  const handleShareProfile = async () => {
    const profileLink = `https://jokk.net/user-profile/${userId}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Profile',
          url: profileLink,
        });
      } catch (error) {
        console.error('Error sharing profile:', error);
      }
    } else {
      setCopied(true);
    }
  };

  const renderItem = (item) => {
    const truncatedDescription = item.description.split(' ').slice(0, 2).join(' ');
    return (
      <Box onClick={() => handleItemPress(item)} style={styles.itemContainer}>
        {item.images && item.images.length > 0 && (
          <img src={item.images[0]} alt={item.title} style={styles.itemImage} />
        )}
        <Typography variant="h6">{item.title}</Typography>
        <Typography variant="body2">{item.price} {item.currency}</Typography>
        <Typography variant="body2">
          {truncatedDescription}...
        </Typography>
      </Box>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
	  <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <Paper style={styles.bannerContainer} onClick={handleBannerPress} disabled={currentUser?.uid !== userId}>
        {profile?.banner ? (
          <img src={profile.banner} alt="Banner" style={styles.bannerImage} />
        ) : (
          <Box style={styles.bannerPlaceholder}>
            <Camera size={24} color="#cccccc" />
            <Typography variant="body1" style={styles.bannerText}>Cliquez pour ajouter une bannière</Typography>
          </Box>
        )}
      </Paper>

      <Box style={styles.profileContainer}>
        {profile?.logo ? (
          <Box style={styles.profileImageContainer}>
            <Avatar src={profile.logo} style={styles.profileImage} />
            {profile.role === 'couturier' && (
              <Box style={styles.officialBadge}>
                <BadgeCheck size={24} color="#1DA1F2" />
              </Box>
            )}
          </Box>
        ) : (
          <Avatar style={styles.profilePlaceholder}>
            <User size={80} />
          </Avatar>
        )}
        <Typography variant="h4">{profile?.Name}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Share2 size={24} />}
          onClick={handleShareProfile}
        >
          Partager le profil
        </Button>
        {copied && (
          <CopyToClipboard text={`https://jokk.net/user-profile/${userId}`}>
            <Button onClick={() => setCopied(false)}>Copier le lien</Button>
          </CopyToClipboard>
        )}
      </Box>

      <Box style={styles.flashyContainer}>
        <Typography variant="body1" style={styles.flashyText}>
          {profile?.bio || "Ajoutez une description de votre profil"}
        </Typography>
        {currentUser?.uid === userId && (
          <IconButton onClick={handleEditPress} style={styles.editIcon}>
            <Edit size={24} color="#FF4500" />
          </IconButton>
        )}
      </Box>

      <Box style={styles.sectionContainer}>
        <Typography variant="h5">Mes Modèles</Typography>
        <Grid container spacing={2}>
          {posts.map(renderItem)}
          {posts.length === 0 && <Typography>Aucun modèle trouvé.</Typography>}
        </Grid>
      </Box>

      <Box style={styles.sectionContainer}>
        <Typography variant="h5">Mes Tissus</Typography>
        <Grid container spacing={2}>
          {tissus.map(renderItem)}
          {tissus.length === 0 && <Typography>Aucun tissu trouvé.</Typography>}
        </Grid>
      </Box>

      <Box style={styles.sectionContainer}>
        <Typography variant="h5">Mes Accessoires</Typography>
        <Grid container spacing={2}>
          {accessories.map(renderItem)}
          {accessories.length === 0 && <Typography>Aucun accessoire trouvé.</Typography>}
        </Grid>
      </Box>
    </Container>
  );
};

const styles = {
  bannerContainer: {
    height: 230,
    width: '100%',
    position: 'relative',
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  bannerPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#f0f0f0',
  },
  bannerText: {
    color: '#cccccc',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -75,
    marginBottom: 20,
  },
  profileImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  profileImage: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    border: '4px solid white',
    objectFit: 'cover',
  },
  officialBadge: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(25%, 25%)',
  },
  profilePlaceholder: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  flashyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    position: 'relative',
  },
  flashyText: {
    textAlign: 'center',
    fontSize: 18,
    fontStyle: 'italic',
  },
  editIcon: {
    position: 'absolute',
    right: 0,
  },
  sectionContainer: {
    marginBottom: 20,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
  },
  itemImage: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    marginBottom: 10,
  },
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  }
};

export default UserProfile;

