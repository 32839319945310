
import '../styles/DeliveryScreen.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@chakra-ui/react';
import { Box, Typography, Modal } from '@mui/material';

const DeliveryScreen = ({ route }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');

  const [relayCountry, setRelayCountry] = useState('');
  const [region, setRegion] = useState('');
  const [relayCity, setRelayCity] = useState('');
  const [relayPhone, setRelayPhone] = useState('');

  const [fetchedData, setFetchedData] = useState(null);
  const [documentId, setDocumentId] = useState(null);

  const [expandedHome, setExpandedHome] = useState(false);
  const [expandedRelay, setExpandedRelay] = useState(false);

  const [couturierAddress, setCouturierAddress] = useState(null);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();  // Utilisation de useLocation pour récupérer state
  const { currentUser } = useContext(AuthContext);
  const [deliveryOptionsMessages, setDeliveryOptionsMessages] = useState([]);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  // Récupération des données passées via navigate
  const { totalPrice, itemId, currency, itemType, serviceFee } = location.state || {};

  useEffect(() => {
    document.title = "Livraison"; 
  }, []);

  // Pour l'effet de survol sur les boutons
const handleHover = (e, isHovering) => {
  const styleKey = e.target.getAttribute('data-style');
  if (isHovering) {
    e.target.style.backgroundColor = styles[styleKey + 'Hover'].backgroundColor;
  } else {
    e.target.style.backgroundColor = styles[styleKey].backgroundColor;
  }
};

  const handleContinueWithoutDeliveryMethod = () => {
    setShowModal(false);
    navigate('/payment', { state: { totalPrice, itemId, currency, itemType, serviceFee } });
  };
  
  const handleNavigationToPayment = () => {
    if (deliveryOptions.length === 0) {
      console.log("No delivery method");
      setShowModal(true);
    } else {
      navigate('/payment', { state: { totalPrice, itemId, currency, itemType, serviceFee } });
    }
  };
  
  useEffect(() => {
    const savedData = loadFromLocalStorage();  // Charger les données sauvegardées
    if (savedData) {
      setName(savedData.name || '');
      setAddress(savedData.address || '');
      setPostalCode(savedData.postalCode || '');
      setCity(savedData.city || '');
      setCountry(savedData.country || '');
      setPhone(savedData.phone || '');
      // Remplir les autres champs pertinents
    }
  }, []);
  

  useEffect(() => {
    const fetchAddressData = async () => {
      try {
        const q = query(
          collection(db, 'deliveryAddresses'),
          where('userId', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const addressDoc = querySnapshot.docs[0];
          const addressData = addressDoc.data();
          setName(addressData.name);
          setAddress(addressData.address);
          setAddress2(addressData.address2);
          setPostalCode(addressData.postalCode);
          setCity(addressData.city);
          setCountry(addressData.country);
          setPhone(addressData.phone);
          setRelayCountry(addressData.relayCountry || '');
          setRegion(addressData.region || '');
          setRelayCity(addressData.relayCity || '');
          setRelayPhone(addressData.relayPhone || '');
          setFetchedData(addressData);
          setDocumentId(addressDoc.id); // Stocker l'ID du document
        } else {
          setDocumentId(null); // Aucun document trouvé, préparation pour création
        }
      } catch (error) {
        console.error('Error fetching address data: ', error);
      }
    };

    const fetchCouturierAddress = async (couturierId) => {
      try {
        const couturierAddressRef = doc(db, 'CouturierAddress', couturierId);
        const docSnap = await getDoc(couturierAddressRef);

        if (docSnap.exists()) {
          setCouturierAddress(docSnap.data());
        } else {
          console.log('No address found for this couturier ID:', couturierId);
        }
      } catch (error) {
        console.error('Error fetching couturier address: ', error);
      }
    };

    const fetchDeliveryOptions = async () => {
      try {
        const q = query(collection(db, 'deliveryOptions'), where('articleId', '==', itemId[0]));
        const querySnapshot = await getDocs(q);
        const options = querySnapshot.docs.map(doc => doc.data());
        setDeliveryOptions(options);
    
        if (options.length > 0) {
          const couturierId = options[0].userId;
          await fetchCouturierAddress(couturierId);
        }
      } catch (error) {
        console.error('Error fetching delivery options: ', error);
      }
    };
    

    fetchAddressData();
    fetchDeliveryOptions();
  }, [currentUser, itemId]);

  const saveAddressToFirestore = async (data) => {
    try {
      if (documentId) {
        const addressRef = doc(db, 'deliveryAddresses', documentId);
        await updateDoc(addressRef, data);
      } else {
        const docRef = await addDoc(collection(db, 'deliveryAddresses'), data);
        console.log('Document written with ID: ', docRef.id);
      }
    } catch (error) {
      console.error('Error adding/updating document: ', error);
    }
  };

  const handleDeliveryCheck = (deliveryOptions, userAddress, vendorAddress) => {
    const messages = [];
  
    deliveryOptions.forEach((option) => {
      if (option.method === 'National') {
        if (userAddress.country === vendorAddress.country) {
          messages.push({
            text: `Pour ${vendorAddress.country}, Frais de livraison: ${option.fee} ${option.currency}`,
            fee: option.fee
          });
        } else {
          messages.push({
            text: 'Livraison indisponible dans votre pays.',
            fee: null
          });
        }
      } else if (option.method === 'International') {
        if (userAddress.country !== vendorAddress.country) {
          messages.push({
            text: `Pour ${userAddress.country}, Frais de livraison: ${option.fee} ${option.currency}`,
            fee: option.fee
          });
        } else {
          messages.push({
            text: 'Livraison indisponible dans votre pays.',
            fee: null
          });
        }
      } else if (option.method === 'Region') {
        if (userAddress.region === vendorAddress.city) {
          messages.push({
            text: `Pour ${vendorAddress.city}, Frais de livraison: ${option.fee} ${option.currency}`,
            fee: option.fee
          });
        } else {
          messages.push({
            text: 'Livraison indisponible dans votre région.',
            fee: null
          });
        }
      }
    });
  
    setDeliveryOptionsMessages(messages);
    setShowDeliveryModal(true);
  };
  

  const saveToLocalStorage = (data) => {
    localStorage.setItem('deliveryData', JSON.stringify(data));
  };
  
  const loadFromLocalStorage = () => {
    const data = localStorage.getItem('deliveryData');
    return data ? JSON.parse(data) : null;
  };

  const handleSubmitHome = () => {
    if (!name || !address || !postalCode || !city || !country || !phone) {
      alert('Erreur', 'Tous les champs marqués d\'un * sont obligatoires.');
      return;
    }
  
    const data = {
      name,
      address,
      address2,
      postalCode,
      city,
      country,
      phone,
      itemId,
      itemType,
      userId: currentUser ? currentUser.uid : null,  // Si l'utilisateur n'est pas connecté, userId sera null
    };
  
    if (fetchedData) {
      const hasChanges = Object.keys(data).some((key) => data[key] !== fetchedData[key]);
      if (!hasChanges) {
        console.log('No changes detected, skipping save');
        navigate('/payment', { state: { totalPrice, itemId, currency, itemType, serviceFee } });
        return;
      }
    }
  
    if (currentUser) {
      saveAddressToFirestore(data);
    } else {
      saveToLocalStorage(data);
    }
  
    const userAddress = { country, region: city };
    handleDeliveryCheck(deliveryOptions, userAddress, couturierAddress);
  };
  
  
  const handleSubmitRelay = () => {
    if (!relayCountry || !region || !relayCity || !relayPhone) {
      alert('Erreur', 'Tous les champs marqués d\'un * sont obligatoires.');
      return;
    }
  
    // Crée un objet data pour l'adresse de livraison
    const data = {
      relayCountry,
      region,
      relayCity,
      relayPhone,
      itemId,
      itemType,
      userId: currentUser ? currentUser.uid : null,  // Si l'utilisateur n'est pas connecté, userId sera null
    };
  
    // Si fetchedData existe, vérifier si les données ont changé
    if (fetchedData) {
      const hasChanges = Object.keys(data).some((key) => data[key] !== fetchedData[key]);
      if (!hasChanges) {
        console.log('No changes detected, skipping save');
        navigate('/payment', { state: { totalPrice, itemId, currency, itemType, serviceFee } });
        return;
      }
    }
  
    if (currentUser) {
      saveAddressToFirestore(data);
    } else {
      saveToLocalStorage(data);
    }
  
    // Vérifier les options de livraison
    const userAddress = { country: relayCountry, region };
    handleDeliveryCheck(deliveryOptions, userAddress, couturierAddress);
  };


  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
      <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
        Retour
      </Button>
        
    <div style={styles.container}>
    
      <Typography variant="body2" sx={{ color: 'red', textAlign: 'center' }}>
        Il est recommandé de créer un compte avant de poursuivre pour une meilleure traçabilité de vos commandes.
        </Typography>
        <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/auth')}
            sx={{ mt: 2 }}
          >
            Se connecter / Créer un compte
          </Button>
      <h1 style={styles.header}>Adresse de Livraison</h1>
      <div style={styles.sectionContainer}>
        <div onClick={() => setExpandedHome(!expandedHome)} style={styles.sectionHeader}>
          <h2 style={styles.sectionHeaderText}>Livraison à domicile {expandedRelay ? '▲' : '▼'}</h2>
        </div>
        {expandedHome && (
          <div style={styles.sectionBody}>
            <input
              style={styles.input}
              placeholder="Nom et prénom *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Adresse *"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Complément d'adresse"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Code postal *"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Ville *"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Pays *"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Numéro de téléphone *"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <button onClick={handleSubmitHome}>Confirmer</button>
          </div>
        )}
      </div>
      <div style={styles.sectionContainer}>
      <div onClick={() => setExpandedRelay(!expandedRelay)} style={styles.sectionHeader}>
        <h2 style={styles.sectionHeaderText}>
          Livraison en point relais {expandedRelay ? '▲' : '▼'}
        </h2>
      </div>

        {expandedRelay && (
          <div style={styles.sectionBody}>
            <input
              style={styles.input}
              placeholder="Pays *"
              value={relayCountry}
              onChange={(e) => setRelayCountry(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Région *"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Ville *"
              value={relayCity}
              onChange={(e) => setRelayCity(e.target.value)}
            />
            <input
              style={styles.input}
              placeholder="Numéro de téléphone *"
              value={relayPhone}
              onChange={(e) => setRelayPhone(e.target.value)}
            />
            <button onClick={handleSubmitRelay}>Confirmer</button>
          </div>
        )}
      </div>

      {showDeliveryModal && (
        <Modal
        open={showDeliveryModal}
        onClose={() => setShowDeliveryModal(false)}
        aria-labelledby="delivery-modal-title"
        aria-describedby="delivery-modal-description"
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
          <Box sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            boxShadow: 24,
            maxWidth: '400px',
            width: '100%',
          }}>
            {deliveryOptionsMessages.map((option, index) => (
              <Box key={index} sx={{
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Typography variant="body1">{option.text}</Typography>
                {option.fee !== null && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowDeliveryModal(false);
                      navigate('/payment', { state: { totalPrice, itemId, currency, itemType, serviceFee, fee: parseFloat(option.fee) } });
                    }}
                    sx={{ mt: 2 }}
                  >
                    Choisir
                  </Button>
                )}
              </Box>
            ))}
            <Button
              variant="contained"
              onClick={() => setShowDeliveryModal(false)}
              sx={{ mt: 2, width: '100%' }}
            >
              Fermer
            </Button>
          </Box>
        </Box>
      </Modal>
      )}

{deliveryOptions.length > 0 && (
  <div style={styles.section}>
    <h3 style={styles.sectionHeader}>Options de livraison - Frais de livraison</h3>
    {deliveryOptions.map((option, index) => (
      <p key={index}>
        {option.method === 'Atelier' ? (
          <span style={styles.freeDeliveryText}>Chez le vendeur - Gratuit</span>
        ) : (
          <>
            <span>{option.method} - </span>
            <span style={styles.boldText}>{option.fee} {option.currency}</span>
          </>
        )}
      </p>
    ))}
  </div>
)}

{showModal && (
  <div style={styles.modal}>
    <div style={styles.modalContent}>
      <p>Vous n'avez pas choisi de méthode livraison. Votre commande sera à récupérer chez le vendeur.</p>
      <div style={styles.modalButtons}>
        <button
          style={{ ...styles.modalButton, backgroundColor: 'red' }}
          onClick={() => setShowModal(false)}
        >
          Annuler
        </button>
        <button
          style={{ ...styles.modalButton, backgroundColor: 'green' }}
          onClick={handleContinueWithoutDeliveryMethod}
        >
          Continuer
        </button>
      </div>
    </div>
  </div>
)}

<button
  style={styles.continueButton}
  onMouseEnter={(e) => handleHover(e, true)}
  onMouseLeave={(e) => handleHover(e, false)}
  onClick={handleNavigationToPayment}
  data-style="continueButton"
>
  Continuer
</button>;

    </div>
    </Box>
  );
  
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
  },
  header: {
    fontSize: '26px',
    fontWeight: 'bold',
    marginBottom: '30px',
    textAlign: 'center',
  },
  sectionContainer: {
    marginBottom: '20px',
    width: '100%',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  sectionHeader: {
    backgroundColor: '#f7f7f7',
    padding: '15px 20px',
    cursor: 'pointer',
    borderBottom: '1px solid #ddd',
    transition: 'background-color 0.3s ease',
  },
  sectionHeaderText: {
    margin: '0',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionBody: {
    padding: '20px',
    backgroundColor: '#fff',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '12px 15px',
    marginBottom: '15px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '6px',
    boxSizing: 'border-box',
    transition: 'border-color 0.3s ease',
  },
  inputFocus: {
    borderColor: '#3f51b5',
  },
  modal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  modalButton: {
    padding: '12px 20px',
    color: '#fff',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  continueButton: {
    marginTop: '20px',
    padding: '15px 25px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  continueButtonHover: {
    backgroundColor: '#2c387e',
  },
  freeDeliveryText: {
    color: 'green',
    fontWeight: 'bold',
  },
  boldText: {
    fontWeight: 'bold',
  },
};

export default DeliveryScreen;

