import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase'; 
import { AuthContext } from '../features/authContext';
import ReactPlayer from 'react-player';
import Select from 'react-select';
import { Container, TextField, Button, Typography, Grid, Card, CardMedia, Alert as MuiAlert } from '@mui/material';

const PostDetailScreen = () => {
  const { id, type } = useParams(); // Get the id and type from the URL
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  // Component states
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priceType, setPriceType] = useState('');
  const [availability, setAvailability] = useState('Disponible');
  const [promotion, setPromotion] = useState(0);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [currency, setCurrency] = useState('');
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });

  // Availability options for the dropdown
  const availabilityOptions = [
    { label: 'Epuisée', value: 'Epuisée' },
    { label: 'Bientôt', value: 'Bientôt' },
    { label: 'Limitée', value: 'Limitée' },
  ];

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      let collectionName;

      // Determine the collection based on the type
      switch(type) {
        case 'accessories':
          collectionName = 'accessories';
          break;
        case 'readytowear':
          collectionName = 'readytowear';
          break;
        case 'tissus':
          collectionName = 'tissus';
          break;
        case 'shoes':
          collectionName = 'shoes';
          break;
        default:
          collectionName = 'patterns';
      }
      
      try {
        // Fetch the document from the correct collection
        const docRef = doc(db, collectionName, id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();

          // Update the state with the document data
          setTitle(data.title || '');
          setDescription(data.description || '');
          setPriceType(data.priceType || '');
          setAvailability(data.availability || 'Disponible');
          setPromotion(data.promotion || 0);
          setImages(data.images || []);
          setVideo(data.video || '');
          setCurrency(data.currency || '');
          setIsEditable(currentUser && currentUser.uid === data.userId); // Check if the current user can edit
        } else {
          throw new Error('Le document n\'existe pas.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchData();
  }, [id, type, currentUser]);

  // Handle the save button click to update the document
  const handleSaveChanges = async () => {
    let collectionName;
    switch(type) {
      case 'accessories':
        collectionName = 'accessories';
        break;
      case 'readytowear':
        collectionName = 'readytowear';
        break;
      case 'tissus':
        collectionName = 'tissus';
        break;
      case 'shoes':
        collectionName = 'shoes';
        break;
      default:
        collectionName = 'patterns';
    }

    try {
      const docRef = doc(db, collectionName, id);

      // Update the document with the modified values
      await updateDoc(docRef, {
        title,
        description,
        priceType,
        availability, 
        promotion,
        currency,
      });

      setAlert({ open: true, message: 'Modifications enregistrées avec succès', severity: 'success' });
      navigate('/couturier-main'); // Navigate to the main page
    } catch (error) {
      console.error('Erreur lors de la mise à jour :', error);
      setAlert({ open: true, message: `Erreur lors de l'enregistrement des modifications: ${error.message}`, severity: 'error' });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Détails du Post
      </Typography>

      {alert.open && (
        <MuiAlert severity={alert.severity} style={{ marginBottom: '16px' }}>
          {alert.message}
        </MuiAlert>
      )}

      <div style={{ marginBottom: '16px' }}>
        <Typography variant="h6">Titre:</Typography>
        {isEditable ? (
          <TextField
            fullWidth
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Titre"
          />
        ) : (
          <Typography variant="body1">{title}</Typography>
        )}
      </div>

      <div style={{ marginBottom: '16px' }}>
        <Typography variant="h6">Description:</Typography>
        {isEditable ? (
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
        ) : (
          <Typography variant="body1">{description}</Typography>
        )}
      </div>

      <div style={{ marginBottom: '16px' }}>
        <Typography variant="h6">Prix:</Typography>
        <Typography variant="body1">{priceType} {currency}</Typography>
      </div>

      <div style={{ marginBottom: '16px' }}>
        <Typography variant="h6">Promotion (%):</Typography>
        {isEditable ? (
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            value={promotion}
            onChange={(e) => {
              const numericValue = parseInt(e.target.value, 10);
              if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 99) {
                setPromotion(numericValue);
              } else {
                setAlert({ open: true, message: 'La promotion doit être comprise entre 0 et 99.', severity: 'error' });
              }
            }}
            placeholder="Promotion"
          />
        ) : (
          <Typography variant="body1">{promotion}%</Typography>
        )}
      </div>

      <div style={{ marginBottom: '16px' }}>
        <Typography variant="h6">Disponibilité:</Typography>
        {isEditable ? (
          <Select
            options={availabilityOptions}
            onChange={(option) => setAvailability(option.value)}
            value={availabilityOptions.find(opt => opt.value === availability)}
            placeholder="Sélectionnez la disponibilité"
          />
        ) : (
          <Typography variant="body1">{availability}</Typography>
        )}
      </div>

      <div style={{ marginBottom: '16px', display: 'flex', overflowX: 'scroll' }}>
        {video && (
          <ReactPlayer
            url={video}
            controls
            width="100%"
            height="200px"
          />
        )}
        {images.map((img, index) => (
          <Card key={index} style={{ marginRight: '16px' }}>
            <CardMedia
              component="img"
              height="140"
              image={img}
              alt={`image-${index}`}
            />
          </Card>
        ))}
      </div>

      {isEditable && (
        <Button variant="contained" color="primary" onClick={handleSaveChanges}>
          Enregistrer les modifications
        </Button>
      )}
    </Container>
  );
};

export default PostDetailScreen;
