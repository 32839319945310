import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Button, TextField, Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Modal from 'react-modal';
import { collection, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import Decimal from 'decimal.js';
import { useNavigate } from 'react-router-dom';

const CommandeClient = () => {
  const [commandes, setCommandes] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const [commandPrice, setCommandPrice] = useState('');
  const [commandCurrency, setCommandCurrency] = useState('XOF');
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommandes = async () => {
      const querySnapshot = await getDocs(collection(db, 'commandes'));
      const commandesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const commandesWithUserNames = await Promise.all(commandesData.map(async (commande) => {
        const userDoc = await getDoc(doc(db, 'users', commande.userId));
        const userName = userDoc.exists() ? userDoc.data().name : 'Utilisateur inconnu';
        return { ...commande, userName };
      }));

      setCommandes(commandesWithUserNames);
    };

    fetchCommandes();
  }, []);

  const handleCommandePress = (commande) => {
    setSelectedCommande(commande);
    setModalVisible(true);
  };

  const handleConfirm = async () => {
    if (!selectedCommande || !currentUser) return;

    const clientId = selectedCommande.userId;
    const feePercentage = new Decimal(0.02);
    const commandPriceDecimal = new Decimal(commandPrice);
    const feeAmount = commandPriceDecimal.times(feePercentage).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
    const totalAmount = commandPriceDecimal.plus(feeAmount).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);

    const message = `${currentUser.name} veut prendre votre commande en charge pour ${commandPriceDecimal.toFixed(2)} ${commandCurrency}. Des frais supplémentaires de ${feeAmount.toFixed(2)} ${commandCurrency} seront appliqués, soit un total de ${totalAmount.toFixed(2)} ${commandCurrency}.`;

    try {
      await addDoc(collection(db, 'notifications'), {
        userId: clientId,
        couturierId: currentUser.uid,
        message,
        read: false,
        status: 'pending',
        timestamp: new Date(),
        type: 'commande',
        itemId: selectedCommande.id,
        commandPrice: commandPriceDecimal.toFixed(2),
        commandCurrency,
      });

      alert('Notification envoyée au client.');
    } catch (error) {
      console.error('Error sending notification:', error);
      alert('Une erreur est survenue lors de l\'envoi de la notification.');
    }

    setModalVisible(false);
    setCommandPrice('');
    setCommandCurrency('XOF');
  };

  const handlePriceInput = (event) => {
    const text = event.target.value;
    const cleanedText = text.replace(/[^0-9.]/g, '');
    const dotCount = cleanedText.split('.').length - 1;
    if (dotCount <= 1) {
      setCommandPrice(cleanedText);
    }
  };

  const renderItem = (item) => (
    <Card key={item.id} sx={{ marginBottom: '16px', width: '300px' }}>
      <CardContent>
        <Typography variant="h6">{item.userName} a publié</Typography>
      </CardContent>
      <CardMedia
        component="img"
        image={item.images[0]}
        alt={item.title}
        sx={{ height: '200px' }}
      />
      <CardContent>
        <Typography variant="h5">{item.title}</Typography>
        <Typography variant="body2" color="textSecondary">{item.description}</Typography>
        <Typography variant="h6">Type de tissu: {item.fabricType}</Typography>
      </CardContent>
      <Button variant="contained" color="primary" onClick={() => handleCommandePress(item)}>
        Voir plus
      </Button>
    </Card>
  );

  return (
    <Container>
      <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ marginTop: '1rem', marginLeft: '1rem' }}
                        onClick={() => navigate('/fabrictodesign')} 
                    >
                        Crée ton modèle de couture avec notre IA!
                    </Button>
      <Typography variant="h4" gutterBottom>
        Choisissez une commande à confectionner
      </Typography>
      <Box sx={{ display: 'flex', overflowX: 'auto', gap: '16px' }}>
        {commandes.map(renderItem)}
      </Box>
      <Modal
  isOpen={isModalVisible}
  onRequestClose={() => setModalVisible(false)}
  contentLabel="Confirmation de la commande"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      maxWidth: '500px',
      width: '100%',
      padding: '2rem',
      borderRadius: '12px',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fait ressortir le modal sur la page
    }
  }}
>
  <Box>
    <Typography variant="h6" gutterBottom>
      Voulez-vous prendre la commande de {selectedCommande?.userName} ?
    </Typography>
    <TextField
      fullWidth
      label="Prix de la commande"
      value={commandPrice}
      onChange={handlePriceInput}
      margin="normal"
    />
    <FormControl fullWidth margin="normal">
      <InputLabel>Devise</InputLabel>
      <Select
        value={commandCurrency}
        onChange={(e) => setCommandCurrency(e.target.value)}
      >
        <MenuItem value="XOF">XOF</MenuItem>
        <MenuItem value="EUR">EUR</MenuItem>
        <MenuItem value="USD">USD</MenuItem>
      </Select>
    </FormControl>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '16px' }}>
      <Button variant="contained" color="primary" onClick={handleConfirm}>
        Oui
      </Button>
      <Button variant="outlined" color="secondary" onClick={() => setModalVisible(false)}>
        Non
      </Button>
    </Box>
  </Box>
</Modal>

    </Container>
  );
};


export default CommandeClient;
